<template>
    <div class="fixed w-full top-0 z-[45] drop-shadow-md">
        <div class="w-full p-4 bg-white drop-shadow-sm">
            <div class="flex items-center justify-between gap-8">
                <!-- Left side -->
                <div
                    class="flex gap-8"
                    :class="[
                        props.layout === 2
                            ? 'flex-col'
                            : 'flex-row items-center',
                    ]"
                >
                    <!-- Logo -->
                    <a class="cursor-pointer w-min" href="/" aria-label="Home">
                        <img
                            class="anti-lazy"
                            :class="[
                                {
                                    'max-h-[2.5rem] max-w-[7rem]':
                                        config.header.logoSize === 'small',
                                },
                                {
                                    'max-h-[3.5rem] max-w-[9rem]':
                                        config.header.logoSize === 'normal',
                                },
                                {
                                    'max-h-[5rem] max-w-[12rem]':
                                        config.header.logoSize === 'large',
                                },
                            ]"
                            :src="props.data.bild[0].url"
                            :alt="props.data.bild[0].alt || 'Header Logo'"
                        />
                    </a>
                    <div
                        class="hidden gap-8 whitespace-nowrap"
                        :class="props.layout === 2 ? 'minM:flex' : 'mc:flex'"
                    >
                        <!-- Links left side -->
                        <template v-for="nav in props.data.navigation">
                            <a
                                v-if="
                                    nav.__typename ==
                                    'navigation_navlink_BlockType'
                                "
                                :href="nav.linkfeld.url"
                                class="text-regular"
                            >
                                {{ nav.linkfeld.text }}
                            </a>

                            <div
                                class="cursor-pointer text-regular"
                                :class="{
                                    'font-bold underline underline-offset-[16px] decoration-2':
                                        showBackground &&
                                        activeMenu?.id === nav.id,
                                }"
                                v-else
                                @click="toggleMenu(nav.id)"
                            >
                                {{ nav.ueberschrift }}
                            </div>
                        </template>
                    </div>
                </div>
                <!-- Right side -->
                <div class="flex">
                    <div
                        class="flex flex-row items-center gap-x-4 gap-y-8 min-h-16"
                        :class="[
                            {
                                'minM:flex-col minM:items-end':
                                    props.layout === 2,
                            },
                        ]"
                    >
                        <!-- Links right side -->
                        <div class="s:hidden flex gap-2 text-small">
                            <a
                                :href="kleinerLink.linkfeld.url"
                                v-for="kleinerLink in props.data.kleineLinks"
                                class="custom-border gap-1 flex items-center"
                            >
                                <i
                                    class="text-large pl-1"
                                    v-if="kleinerLink.iconName"
                                    :class="[
                                        config.global.iconStyle,
                                        'fa-' + kleinerLink.iconName,
                                    ]"
                                ></i>
                                <span
                                    v-if="kleinerLink.linkfeld.customText"
                                    class="whitespace-nowrap pl-0.5 pr-1"
                                    >{{ kleinerLink.linkfeld.customText }}</span
                                >
                            </a>
                            <NTheme
                                v-if="Object.keys(config.languages).length > 1"
                            >
                                <NSelect
                                    :show-checkmark="false"
                                    :value="languageValue"
                                    :options="languageOptions"
                                    @update:value="updateLanguage"
                                ></NSelect>
                            </NTheme>
                        </div>

                        <!-- Buttons right side -->
                        <div class="hidden minM:flex gap-2">
                            <a
                                :href="button.linkfeld.url"
                                v-for="(button, index) in props.data
                                    .headerButtons"
                                class="flex gap-2 items-center"
                                :class="[
                                    index === 0
                                        ? config.header.secondaryButtonClass
                                        : config.header.primaryButtonClass,
                                ]"
                            >
                                <i
                                    v-if="button.iconName"
                                    :class="[
                                        config.global.iconStyle,
                                        'fa-' + button.iconName,
                                        'leading-[44px]',
                                    ]"
                                ></i>
                                <span>{{ button.linkfeld.customText }}</span>
                            </a>
                        </div>
                    </div>

                    <!-- 🍔 Hamburger menu -->
                    <div
                        class="cursor-pointer flex items-center justify-end h-16 s:w-28 content:w-20 pr-8"
                        @click="toggleMobileMenu()"
                        :class="[
                            props.layout === 2 ? 'minM:hidden' : 'mc:hidden',
                        ]"
                    >
                        <i
                            class="text-xl block"
                            :class="[
                                config.global.iconStyle,
                                mobileMenuOpen
                                    ? 'fa-times'
                                    : config.header.menuIcon,
                            ]"
                        ></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- Desktop menu -->
        <div id="desktop-menu-container" class="relative m:hidden -z-10">
            <div
                class="absolute w-full overflow-y-auto max-h-[calc(100vh-100px)]"
                :style="{
                    transform: `translateY(${showBackground ? '0px' : '-100%'})`,
                }"
            >
                <div
                    class="w-full px-side bg-white grid-cols-3 gap-20 content:gap-12 l:!gap-8 py-12 grid"
                    :class="
                        config.global.blockDecoration.enabled ? 'pb-6' : 'pb-12'
                    "
                >
                    <!-- Nav groups -->
                    <div class="border-[#b3b3b3] border-r flex flex-col gap-8">
                        <template v-for="child in activeMenu?.children">
                            <!-- Nav link -->
                            <a
                                v-if="
                                    child.__typename ==
                                    'navigation_navlink_BlockType'
                                "
                                :href="child.linkfeld.url"
                                class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                            >
                                {{ child.linkfeld.text }}
                            </a>
                            <!-- Nav group -->
                            <div
                                class="cursor-pointer text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                :class="{
                                    'text-color-primary underline underline-offset-[16px]':
                                        activeSubMenu === child.id,
                                }"
                                v-else
                                @click="toggleSubMenu(child.id)"
                            >
                                {{ child.ueberschrift }}
                            </div>
                        </template>
                    </div>
                    <!-- Nav sub groups -->
                    <div class="">
                        <template v-for="child in activeMenu?.children">
                            <div
                                class="flex flex-col gap-8"
                                :class="{ hidden: activeSubMenu !== child.id }"
                                v-if="
                                    child.__typename ==
                                    'navigation_subNavgruppe_BlockType'
                                "
                            >
                                <a
                                    :href="subChild.linkfeld.url"
                                    class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                    v-for="subChild in child.children"
                                >
                                    {{ subChild.linkfeld.text }}
                                </a>
                            </div>
                        </template>
                    </div>
                    <!-- Highlight -->
                    <div class="">
                        <div
                            v-if="
                                activeMenu?.highlightBild.length > 0 ||
                                activeMenu?.lead ||
                                activeMenu?.text ||
                                activeMenu?.linkfeld.url ||
                                activeMenu?.untertitel
                            "
                            class="p-6 relative"
                            :class="[config.header.bgColor]"
                        >
                            <div
                                v-if="activeMenu.untertitel"
                                class="mb-2 text-small-700"
                            >
                                {{ activeMenu.untertitel }}
                            </div>

                            <img
                                v-if="activeMenu.highlightBild.length > 0"
                                class="w-full mb-4 aspect-video anti-lazy"
                                :src="activeMenu.highlightBild[0].url"
                                :alt="activeMenu.highlightBild[0].alt"
                            />

                            <div
                                v-if="activeMenu.lead"
                                class="mb-1 text-regular-700"
                            >
                                {{ activeMenu.lead }}
                            </div>
                            <div v-if="activeMenu.text" class="mb-4 text-small">
                                {{ activeMenu.text }}
                            </div>
                            <a
                                v-if="activeMenu.linkfeld.url"
                                :href="activeMenu.linkfeld.url"
                                class="text-small decorated-link"
                            >
                                {{ activeMenu.linkfeld.text }}
                                <i
                                    class="fa-caret-right"
                                    :class="[config.global.iconStyle]"
                                ></i>
                            </a>
                            <div
                                v-if="config.global.blockDecoration.enabled"
                                class="decoration-offset w-full"
                            ></div>
                            <div
                                v-if="config.global.blockDecoration.enabled"
                                class="absolute bottom-0 left-0 right-0 !h-6"
                                :class="[
                                    'decoration-' +
                                        config.global.blockDecoration.style +
                                        '-inverse',
                                ]"
                            ></div>
                        </div>
                    </div>
                </div>

                <div
                    class="pb-24 cursor-pointer"
                    @click="
                        activeMenu
                            ? toggleMenu(activeMenu.id)
                            : toggleMobileMenu()
                    "
                >
                    <div
                        v-if="config.global.blockDecoration.enabled"
                        :class="`decoration-${config.global.blockDecoration.style} !bg-white`"
                    />
                </div>
            </div>
        </div>

        <!-- Mobile menu -->
        <div
            id="mobile-menu-container"
            class="relative -z-10"
            :class="props.layout === 2 ? 'minM:hidden' : 'mc:hidden'"
            :style="{
                transform: `translateY(${showBackground ? '0px' : '-100vh'})`,
            }"
        >
            <div
                class="absolute w-full max-h-[calc(100vh-100px)] overflow-y-auto"
            >
                <div
                    class="flex flex-col gap-8 px-side bg-white pt-8 minM:pb-8"
                >
                    <!-- Nav groups -->
                    <template v-for="nav in props.data.navigation">
                        <!-- Nav link -->
                        <a
                            v-if="
                                nav.__typename == 'navigation_navlink_BlockType'
                            "
                            :href="nav.linkfeld.url"
                            class="text-f5 font-secondary !font-bold !not-italic"
                        >
                            {{ nav.linkfeld.text }}
                        </a>
                        <!-- Nav group -->
                        <div
                            v-else
                            class="cursor-pointer text-f5 font-secondary !font-bold !not-italic"
                            :class="{
                                'underline underline-offset-[16px] decoration-2 text-color-primary':
                                    activeMenu?.id === nav.id && !activeSubMenu,
                                'text-black opacity-20':
                                    activeMenu && activeMenu?.id !== nav.id,
                            }"
                            @click="toggleMenu(nav.id)"
                        >
                            {{ nav.ueberschrift }}
                        </div>
                        <div
                            class="flex flex-col gap-8 pl-4"
                            :class="{ hidden: activeMenu?.id !== nav.id }"
                        >
                            <!-- Nav sub groups -->
                            <template v-for="subchild in nav.children">
                                <a
                                    v-if="
                                        subchild.__typename ==
                                        'navigation_navlink_BlockType'
                                    "
                                    :href="subchild.linkfeld.url"
                                    class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                    :class="{
                                        'text-black opacity-20':
                                            activeSubMenu &&
                                            activeSubMenu !== subchild.id,
                                    }"
                                >
                                    {{ subchild.linkfeld.text }}
                                </a>
                                <div
                                    class="cursor-pointer text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                    :class="{
                                        'text-color-primary underline underline-offset-[16px] decoration-2':
                                            activeSubMenu === subchild.id,
                                        'text-black opacity-20':
                                            activeSubMenu &&
                                            activeSubMenu !== subchild.id,
                                    }"
                                    v-else
                                    @click="toggleSubMenu(subchild.id)"
                                >
                                    {{ subchild.ueberschrift }}
                                </div>
                                <div
                                    class="flex flex-col gap-8 pl-4"
                                    :class="{
                                        hidden: activeSubMenu !== subchild.id,
                                    }"
                                >
                                    <template
                                        v-for="subsubchild in subchild.children"
                                    >
                                        <a
                                            v-if="
                                                subsubchild.__typename ==
                                                'navigation_navlink_BlockType'
                                            "
                                            :href="subsubchild.linkfeld.url"
                                            class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                        >
                                            {{ subsubchild.linkfeld.text }}
                                        </a>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>

                    <!-- Mobile Kleine Links & Header Buttons -->
                    <div
                        class="flex minM:hidden flex-col gap-8 m:pb-8 m:border-t border-[#b3b3b3] pt-6"
                    >
                        <div class="flex md:hidden flex-col px-2">
                            <div class="flex gap-4">
                                <!-- kleineLinks that don't have a customText set -->
                                <template
                                    v-for="kleinerLink in props.data
                                        .kleineLinks"
                                >
                                    <a
                                        :href="kleinerLink.linkfeld.url"
                                        v-if="
                                            !kleinerLink.linkfeld.customText &&
                                            kleinerLink.iconName
                                        "
                                        class="custom-border-on-child py-2 mb-2"
                                    >
                                        <div>
                                            <i
                                                class="text-2xl pr-4"
                                                :class="[
                                                    config.global.iconStyle,
                                                    'fa-' +
                                                        kleinerLink.iconName,
                                                ]"
                                            ></i>
                                        </div>
                                    </a>
                                </template>
                            </div>
                            <!-- Kleine Links -->
                            <div class="flex md:hidden flex-col gap-2 mb-2">
                                <template
                                    v-for="kleinerLink in props.data
                                        .kleineLinks"
                                >
                                    <a
                                        class="flex gap-2 items-center py-2 text-lg"
                                        :href="kleinerLink.linkfeld.url"
                                        v-if="kleinerLink.linkfeld.customText"
                                    >
                                        <i
                                            class="text-2xl w-8"
                                            v-if="kleinerLink.iconName"
                                            :class="[
                                                config.global.iconStyle,
                                                'fa-' + kleinerLink.iconName,
                                            ]"
                                        ></i>
                                        {{ kleinerLink.linkfeld.customText }}
                                    </a>
                                </template>
                            </div>
                            <div
                                class="flex justify-end gap-4 text-xl"
                                v-if="Object.keys(config.languages).length > 1"
                            >
                                <div
                                    v-for="(lang, arr, i) in config.languages"
                                    class="uppercase cursor-pointer py-2"
                                    :class="{
                                        'custom-border !pr-4':
                                            i !==
                                            Object.keys(config.languages)
                                                .length -
                                                1,
                                        'text-regular-700 !text-xl':
                                            locale === lang,
                                    }"
                                    @click="updateLanguage(lang)"
                                >
                                    {{ lang }}
                                </div>
                            </div>
                        </div>

                        <div class="flex minM:hidden flex-col gap-4">
                            <a
                                :href="button.linkfeld.url"
                                v-for="(button, index) in props.data
                                    .headerButtons"
                                class="flex gap-2 items-center"
                                :class="[
                                    index === 0
                                        ? config.header.primaryButtonClass
                                        : config.header.secondaryButtonClass,
                                ]"
                            >
                                <i
                                    v-if="button.iconName"
                                    :class="[
                                        config.global.iconStyle,
                                        'fa-' + button.iconName,
                                    ]"
                                ></i>
                                {{ button.linkfeld.customText }}
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="bg-transparent cursor-pointer"
                    :class="
                        config.global.blockDecoration.enabled
                            ? 'pb-36'
                            : 'pb-48'
                    "
                    @click="toggleMobileMenu()"
                >
                    <div
                        v-if="config.global.blockDecoration.enabled"
                        :class="`decoration-${config.global.blockDecoration.style} !bg-white`"
                    />
                </div>
            </div>
        </div>
    </div>

    <!-- background -->
    <div
        class="fixed z-40 top-0 left-0 bottom-0 right-0 bg-black transition-opacity duration-300 cursor-pointer"
        :class="showBackground ? 'opacity-50' : 'opacity-0 pointer-events-none'"
        @click="toggleMobileMenu()"
    ></div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { CONFIG_OPTIONS as config, t } from "@src/globals";
import { NSelect } from "naive-ui";
import { getLocaleFromUrl, getUnLocalizedPath } from "@src/i18n/utils";
import NTheme from "./common/NTheme.vue";

const props = defineProps<{
    data: any;
    layout: number;
    _locale: "de" | "en" | "fr" | "it";
    detailPageData?: {
        __typename: string;
        identifier: string;
        name: string;
    };
}>();

const locale = props._locale || getLocaleFromUrl(new URL(window.location.href));

// Add search link to kleineLinks if it doesn't exist
if (
    props.data.kleineLinks.findIndex((l) => l.linkfeld.url === "/search") === -1
)
    props.data.kleineLinks.push({
        iconName: "magnifying-glass",
        linkfeld: { url: "/search", customText: t("search") },
    });

const dropdownMenues = ref([]);
const activeMenu = ref(null);
const activeSubMenu = ref(null);
const mobileMenuOpen = ref(false);

if (props.data.navigation) {
    props.data.navigation.forEach((item) => {
        if (item.__typename === "navigation_navgruppe_BlockType") {
            dropdownMenues.value.push(item);
        }
    });
}

function toggleMenu(id: number) {
    const _prevId = activeMenu.value?.id;
    if (activeMenu.value?.id !== id) {
        // change active menu and close sub menu
        activeMenu.value = dropdownMenues.value.find((m) => m.id === id);
        activeSubMenu.value = null;
    } else if (mobileMenuOpen.value) {
        // close menu
        activeMenu.value = null;
    }
    if (!mobileMenuOpen.value) {
        toggleBackground(showBackground.value ? _prevId !== id : true);
    }
}

function toggleSubMenu(id) {
    if (activeSubMenu.value === id) {
        activeSubMenu.value = null;
    } else {
        activeSubMenu.value = id;
    }
}

function toggleMobileMenu() {
    mobileMenuOpen.value = !mobileMenuOpen.value;
    toggleBackground();

    activeMenu.value = null;
    activeSubMenu.value = null;
}

const showBackground = ref(false);
function toggleBackground(newState?: boolean) {
    showBackground.value = newState ?? !showBackground.value;

    const bodyStyle = document.body.style;
    const doc = document.documentElement;

    // disable content scrolling while menu is open
    if (
        showBackground.value && // menu should be opened
        bodyStyle.position !== "fixed" // and menu is not already open
    ) {
        // get scroll position before setting position
        const scrollPosition = `${-doc.scrollTop}px`;
        bodyStyle.position = "fixed";
        bodyStyle.overflowY = "scroll";
        bodyStyle.width = "100%";
        bodyStyle.top = scrollPosition;
    } else {
        bodyStyle.position = "static";
        bodyStyle.overflowY = "auto";
        // set scroll AFTER position
        doc.scrollTop = -parseInt(bodyStyle.top);
    }
}

const languageValue = ref(locale);
const localePaths = config.paths;
const languageOptions = Object.values(config.languages).map((lang) => ({
    label: lang.toUpperCase(),
    value: lang,
}));

/**
 * Language switcher
 */

import axios from "redaxios";

async function updateLanguage(targetLocale: any) {
    languageValue.value = targetLocale;
    const targetLocalePath = localePaths[targetLocale]
        ? `/${localePaths[targetLocale]}`
        : "";

    const url = new URL(window.location.href);
    let targetSlug = "";

    if (props.detailPageData?.identifier) {
        targetSlug = (await getTranslatedSlug(targetLocale))?.slug;
    } else {
        targetSlug = getUnLocalizedPath(url.pathname, locale);
    }

    if (targetSlug === undefined) {
        throw new Error(
            `No translated slug found for "${url.pathname}"\n[${props.detailPageData}] => [${targetLocale}]`,
        );
    }

    const newPath = `${targetLocalePath}/${targetSlug}`;
    window.location.href = newPath;
}

async function getTranslatedSlug(targetLocale: string): Promise<{
    slugId: string;
    locale: string;
    slug: string;
}> {
    return new Promise((resolve) => {
        axios
            .post<{
                slugId: string;
                locale: string;
                slug: string;
            }>("/api/slug", {
                slugId: props.detailPageData.identifier,
                locale: targetLocale,
                name: props.detailPageData.name,
                type: props.detailPageData.__typename,
            })
            .then((res) => resolve(res.data));
    });
}
</script>

<style lang="scss" scoped>
.custom-border:not(:last-child) {
    padding-right: 0.5rem;
    position: relative;
    &:after {
        --border-height: 70%;
        content: "";
        position: absolute;
        right: 0;
        bottom: calc((100% - var(--border-height)) / 2);
        height: var(--border-height);
        border-right: 1px solid black;
    }
}
.custom-border-on-child:not(:last-child) {
    i {
        position: relative;
        &:after {
            --border-height: 50%;
            content: "";
            position: absolute;
            right: 0;
            bottom: calc((100% - var(--border-height)) / 2);
            height: var(--border-height);
            border-right: 1px solid black;
        }
    }
}
.custom-border-on-child:last-child,
.custom-border:last-child {
    i {
        margin-right: 0;
    }
}

@media (prefers-reduced-motion: no-preference) {
    #mobile-menu-container {
        @apply transition-transform duration-300;
    }
    #desktop-menu-container > div {
        @apply transition-transform duration-500;
    }
}
</style>
